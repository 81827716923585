import React from 'react';

const BankDetailsTable = ({ Bankdetails, ListingDetails }) => {
    // Define CSS for the table and its elements for consistency and readability
    const tableStyle = {
        width: '100%', // Full width to utilize space effectively
        borderCollapse: 'collapse', // Ensures that borders between cells are merged
        marginTop: '20px', // Provides space above the table
        marginBottom: '20px', // Provides space below the table
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)' // Subtle shadow for a slight depth effect
    };

    const headerStyle = {
        backgroundColor: '#f2f2f2', // Light gray background for header cells
        padding: '10px 15px', // Padding inside header cells for spacing
        fontSize: '16px', // Font size for better visibility
        fontWeight: 'bold', // Bold font for header text
        textTransform: 'uppercase', // Uppercase text for a professional look
        borderBottom: '2px solid #ddd' // Defines a solid border for separation
    };

    const cellStyle = {
        padding: '10px 15px', // Padding for content cells to align text properly
        fontSize: '16px', // Consistent font size for readability
        borderBottom: '1px solid #ddd' // Light border to subtly separate rows
    };

    
    return (
        <table style={tableStyle}>
            <tbody>
                <tr>
                    <td style={headerStyle}>Kontoinhaber</td>
                    <td style={cellStyle}>{Bankdetails.full_name}</td>
                </tr>
                <tr>
                    <td style={headerStyle}>IBAN</td>
                    <td style={cellStyle}>{Bankdetails.iban}</td>
                </tr>
                <tr>
                    <td style={headerStyle}>Zahlungsgrund</td>
                    <td style={cellStyle}>{ListingDetails.zip}-{new Date().getFullYear()}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default BankDetailsTable;
