// src/PropertyDetails.js
import React from 'react';
import './PropertyDetails.css';
import { AiOutlineLink } from 'react-icons/ai';
import addressSVG from './address_map.svg';
import MapboxMap from './MapBoxGl';
import useListingDetails from './api';
import ReserveNow from './ButtonRedirectReserve';
const PropertyDetails = ({ userdetals, listare }) => {
    const [data, loading, error] = useListingDetails(userdetals, listare);

    // Debugging logs
  

    // Handle loading and error states
    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: 'red' }}>Error: {error}</p>;

    // Ensure data is available before accessing properties
    const ListingDetails = data?.listing ?? null;
    if (!ListingDetails) return <p>No listing details available.</p>;

    // Parse JSON fields safely
    let features = [];
    try {
        features = ListingDetails.features ? JSON.parse(ListingDetails.features) : [];
    } catch (err) {
        console.error("Failed to parse features:", err);
        features = [];
    }

    // Convert prices and area to numeric values
    const numericPrice = parseFloat(ListingDetails.price?.replace(',', '.') || '0');
    const numericSizeArea = parseFloat(ListingDetails.sizeArea?.replace(',', '.') || '0');
    const totalWarmCost = numericPrice + parseFloat(ListingDetails.warmCost?.replace(',', '.') || '0');

    // Calculate price per area
    const pricePerArea = numericSizeArea > 0 ? numericPrice / numericSizeArea : 0;

    // Format price in German locale
    const formatPrice = (price) => {
        const numericValue = parseFloat(String(price).replace(',', '.'));
        return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(numericValue);
    };
    return (
        <div className="property-details">
            {/* Price Section */}
            <div className="price-section">
                <div className="price-row">
                    <table>
                        <thead>
                            <tr>
                                <th className="price price2 thclassmargin" style={{ margin: '15px' }}>{formatPrice(ListingDetails.price)} €</th>
                                <th className='price2 thclassmargin'>{ListingDetails.rooms}</th>
                                <th className='price2 thclassmargin' >{ListingDetails.sizeArea} m²</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='price2 price23 thclassmargin'>Kaltmiete {isNaN(pricePerArea) ? "N/A" : formatPrice(pricePerArea)} €/m²</td>
                                <td className='price2 price23 thclassmargin'>Zim.</td>
                                <td className='price2 price23 thclassmargin'>Fläche</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="price-row" style={{ justifyContent: '', marginTop: '10px', borderBottom: '1px solid #e0e0e0', paddingBottom: '10px' }}>
                    <table>
                        <thead>
                            <tr>
                                <th className="price234 price2 thclassmargin" style={{ margin: '15px' }}>{formatPrice(totalWarmCost)} €</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='price2 price23 thclassmargin'>Warmmiete</td>

                            </tr>
                        </tbody>
                    </table>
                </div>


            </div>

            {/* Property Title */}
            <h3 className="property-title">{ListingDetails.title}</h3>

            {/* Address Section */}
            <div className="address-section">
                <p className="section-label">Adresse</p>
                <div className="address-details">
                    <img src={addressSVG} alt="Address Map" className="icon addressSVG" />
                    <div>
                        <p>{ListingDetails.address}</p>
                        <a href="#asd" className="map-link">
                            <AiOutlineLink className="icon" /> Auf Karte zeigen
                        </a>
                    </div>
                </div>
            </div>

            {/* Features Section */}
            <div className="property-details">
                <h2>{ListingDetails.title}</h2>

                <p><strong>Address:</strong> {ListingDetails.address}</p>

                {/* Features Section */}
                <div className="features">
                {features.length > 0 ? (
                    features.map((feature, index) => (
                        <span key={index} className="feature">{feature}</span>
                    ))
                ) : (
                    <p>Keine besonderen Merkmale</p>
                )}
            </div>
            </div>

            {/* Detailed Information Section */}
            <div className="details" style={{ borderBottom: '1px solid rgb(224, 224, 224)' }}>
                <div className="detail-row">
                    <span className="detail-title">Typ:</span>
                    <span className="detail-info">{ListingDetails.propertyType}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Etage:</span>
                    <span className="detail-info">{ListingDetails.etaj}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Wohnfläche ca.:</span>
                    <span className="detail-info">{ListingDetails.sizeArea} m²</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Bezugsfrei ab:</span>
                    <span className="detail-info">{ListingDetails.vacantFrom}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Zimmer:</span>
                    <span className="detail-info">{ListingDetails.rooms}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Schlafzimmer:</span>
                    <span className="detail-info">{ListingDetails.bedroom}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Badezimmer:</span>
                    <span className="detail-info">{ListingDetails.bathroom}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Haustiere:</span>
                    <span className="detail-info">{ListingDetails.pets}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Internet:</span>
                    <a href="#asd" className="detail-info link">{ListingDetails.internet}</a>
                </div>
            </div>


            <div className="details" style={{ borderBottom: '1px solid rgb(224, 224, 224)' }}>
                <h2>Kosten</h2>
                <div className="detail-row">
                    <span className="detail-title">Kaltmiete:</span>
                    <span className="detail-info">{ListingDetails.propertyType}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Preis/m²:</span>
                    <span className="detail-info">{ListingDetails.etaj}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Nebenkosten:</span>
                    <span className="detail-info">{ListingDetails.sizeArea} m²</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Heizkosten:</span>
                    <span className="detail-info">{ListingDetails.vacantFrom}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Gesamtmiete:</span>
                    <span className="detail-info">{ListingDetails.rooms}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-title">Kaution o. Genossenschafts­anteile:</span>
                    <span className="detail-info">{ListingDetails.bedroom}</span>
                </div>

            </div>

            <div className="details" style={{ borderBottom: '1px solid rgb(224, 224, 224)' }}>
                <h2>Karte</h2>
                <div>
                    {ListingDetails.address}
                </div>

                <div>
                    <MapboxMap address={ListingDetails.address} zip={ListingDetails.zip} city={ListingDetails.city} />
                </div>

            </div>
            <br></br><br></br><br></br>
            <ReserveNow bank_id={ListingDetails.bank_account_id} />
        </div>
    );
};

export default PropertyDetails;
